<template>
    <div class="sourcestore-container">
        <div class="common-first-part">
            <div>
                <p>专业资源库</p>
                <span>包含教学资源库、实训资源库等，为教师备课、授课提供资源，促进线上线下混合式教学</span>
            </div>
            <img :src="require('@/assets/images/index/source-1.png')" alt="">
        </div>
        <div class="third-part">
            <p class="common-title">资源形式</p>
            <span>按课程知识点及实训技能点组织的活动案例、日常教学案例、</span>
            <span>常见教具案例、突发事件处理案例等</span>
            <div>
                <div class="part-items">
                    <div class="icons-outer"><div><img :src="require('@/assets/images/index/icon-11.png')" alt=""></div></div>
                    <p>微课</p>
                </div>
                <div class="part-items">
                    <div class="icons-outer"><div><img :src="require('@/assets/images/index/icon-12.png')" alt=""></div></div>
                    <p>课件</p>
                </div>
                <div class="part-items">
                    <div class="icons-outer"><div><img :src="require('@/assets/images/index/icon-13.png')" alt=""></div></div>
                    <p>教案</p>
                </div>
                <div class="part-items">
                    <div class="icons-outer"><div><img :src="require('@/assets/images/index/icon-14.png')" alt=""></div></div>
                    <p>试卷</p>
                </div>
                <div class="part-items">
                    <div class="icons-outer"><div><img :src="require('@/assets/images/index/icon-15.png')" alt=""></div></div>
                    <p>教学演示视频</p>
                </div>
                <div class="part-items">
                    <div class="icons-outer"><div><img :src="require('@/assets/images/index/icon-16.png')" alt=""></div></div>
                    <p>专家点评视频</p>
                </div>
            </div>
        </div>
        <div class="second-part">
            <p class="common-title">五大领域资源库</p>
            <img class="img1" :src="require('@/assets/images/index/source-3.png')" alt="">
            <img class="img2" :src="require('@/assets/images/index/source-4.png')" alt="">
            <img class="img3" :src="require('@/assets/images/index/source-5.png')" alt="">
            <img class="img4" :src="require('@/assets/images/index/source-6.png')" alt="">
            <img class="img5" :src="require('@/assets/images/index/source-7.png')" alt="">
        </div>
        <div class="forth-part">
            <img :src="require('@/assets/images/index/fifth.png')" alt="">
            <div>
                <p class="common-title">教学资源库精彩案例</p>
                <span>清华社出品的《松果自然幼儿教育课程》获评为教育部学校规划建设发展中心未来学校（中小学、幼儿园）实验研究课题。</span>
                <button class="learn-more"><a href="https://sgzr.wqketang.com/" target="_blank">立即查看</a></button>
            </div>
        </div>
        <div class="fifth-part">
            <p class="common-title">实训资源库</p>
            <img class="img1" :src="require('@/assets/images/index/a-5.png')" alt="">
            <img class="img2" :src="require('@/assets/images/index/a-1.png')" alt="">
            <img class="img3" :src="require('@/assets/images/index/a-2.png')" alt="">
            <img class="img4" :src="require('@/assets/images/index/a-4.png')" alt="">
            <img class="img5" :src="require('@/assets/images/index/a-3.png')" alt="">
        </div>
        <div class="seventh-part">
            <div class="left-info">
                <p class="common-title">实训资源库</p>
                <span>针对与学前教育专业的核心课程提供匹配的实训案例。帮助学生完善学前教育专业知识结构的搭建，协助学生掌握学前教育专业的技能。</span>
            </div>
            <el-carousel indicator-position="none" arrow="always">
                <el-carousel-item v-for="index in 6" :key="index">
                    <div class="slide-items">
                        <img :src="require('@/assets/images/index/b-'+index+'.png')" alt="">
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>
<script>
export default {
    components: {  },
    props: {},
    data() {
        return {
        }
    },
    created() {},
    mounted() {},
    methods: {
        
    }
}
</script>
<style lang='scss' scoped>
.sourcestore-container {
    .second-part {
        margin: 16px 0;
        background: url('./../../assets/images/index/source-2.png') no-repeat center;
        background-size: cover;
        height: 440px;
        padding: 50px;
        box-sizing: border-box;
        text-align: center;
        position: relative;
        &>img {
            position: absolute;
            // animation: float_b 8s ease-in-out .5s infinite alternate;
        }
        .img1 {
            width: 120px;
            top: 94px;
            left: 219px;
            animation: float_b 10s ease-in-out infinite alternate;
        }
        .img2 {
            width: 80px;
            top: 154px;
            left: 478px;
            animation: float_a 10s ease-in-out 1s infinite alternate;
        }
        .img3 {
            width: 110px;
            top: 84px;
            right: 190px;
            animation: float_a 8s ease-in-out infinite;
        }
        .img4 {
            width: 120px;
            bottom: 71px;
            right: 357px;
            animation: float_b 8s ease-in-out .5s infinite alternate;
        }
        .img5 {
            width: 80px;
            left: 299px;
            bottom: 91px;
            animation: float_b 6s ease-in-out 1s infinite alternate;
        }
    }
    .fifth-part {
        margin: 16px 0;
        background: url('./../../assets/images/index/source-2.png') no-repeat center;
        background-size: cover;
        height: 440px;
        padding: 50px;
        box-sizing: border-box;
        text-align: center;
        position: relative;
        &>img {
            position: absolute;
            // animation: float_b 8s ease-in-out .5s infinite alternate;
        }
        .img1 {
            width: 96px;
            top: 84px;
            left: 253px;
            animation: float_b 10s ease-in-out infinite alternate;
        }
        .img2 {
            width: 130px;
            bottom: 68px;
            left: 188px;
            animation: float_a 10s ease-in-out 1s infinite alternate;
        }
        .img3 {
            width: 112px;
            top: 164px;
            left: 534px;
            animation: float_a 8s ease-in-out infinite;
        }
        .img4 {
            width: 96px;
            top: 106px;
            right: 172px;
            animation: float_b 8s ease-in-out .5s infinite alternate;
        }
        .img5 {
            width: 120px;
            right: 285px;
            bottom: 80px;
            animation: float_b 6s ease-in-out 1s infinite alternate;
        }
    }
    .third-part {
        height: 440px;
        padding: 52px 105px 78px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        &>p {
            margin-bottom: 15px;
        }
        &>span {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 26px;
        }
        &>div {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 50px;
            .part-items {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0 25px;
                .icons-outer {
                    width: 120px;
                    height: 120px;
                    background: #ECF2FA;
                    border-radius: 3px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    &>div {
                        width: 77px;
                        height: 77px;
                        background: #FFFFFF;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        &>img {
                            width: 48px;
                            height: 48px;
                        }
                    }
                }
                &>p {
                    font-size: 18px;
                    color: #333333;
                    margin-top: 15px;
                }
            }
        }
    }
    .forth-part {
        height: 440px;
        padding: 70px 132px 54px 219px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        margin-bottom: 80px;
        margin-top: 16px;
        &>img {
            height: 316px;
        }
        &>div {
            width: 440px;
            &>p {
                margin-bottom: 14px;
            }
            &>span {
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                display: block;
                height: 104px;
                margin-bottom: 14px;
            }
        }
    }
    .seventh-part {
        width: 100%;
        height: 440px;
        background: #F6F9FD;
        margin-bottom: 80px;
        box-sizing: border-box;
        padding: 90px 100px 90px 150px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .left-info {
            &>span {
                width: 317px;
                margin-top: 25px;
                display: block;
            }
        }
        /deep/ .el-carousel {
            width: 500px;
            .el-carousel__container {
                height: 258px;
            }
        }
        .slide-items {
            width: 100%;
            text-align: center;
            &>img {
                width: 258px;
            }
        }
    }
}
@keyframes float_b {
    0% {
        transform: none;
    }
    33% {
        transform: translate(-6px,6px) rotate(-5deg);
    }
    66% {
        transform: translate(-4px,-5px) rotate(-3deg);
    }
    100% {
        transform: translate(-1px,5px) rotate(0);
    }
}
@keyframes float_a {
    0% {
        transform: none;
    }
    25% {
        transform: translate(5px,-8px) scale(1.05) rotate(
    -10deg);
    }
    50% {
        transform: translate(3px) scale(1) rotate(0);
    }
    75% {
        transform: translate(5px,-8px) scale(1) rotate(
    8deg);
    }
    100% {
        transform: none;
    }
}
</style>